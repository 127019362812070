import React, { useState } from 'react';
import { Button, Divider, Modal, Segment } from 'semantic-ui-react';

function ContactModal( props ) {
  // State to control the visibility of the modal
  const [open, setOpen] = useState(false);

  return (
    <div>
      {/* Button to trigger modal */}
      <Button onClick={() => setOpen(true)}
              color='gradient'
              as='a'
              style={{ marginLeft: '0.5em', marginBottom: props.marginbottom}}>
              Contact
      </Button>

      {/* Modal Definition */}
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        size='small'
        style={{borderRadius: '10px'}}
      >
        <Modal.Content style={{borderRadius: '30px'}}>
          <Segment textAlign='center' vertical style={{paddingTop: '7em', paddingBottom: '5em', borderRadius: '30px'}}>
            <h3 style={{fontSize: '2em', paddingBottom: '0.7em'}}>Joeri Kortenhorst </h3>
            <Divider
              style = {{height: '3px', borderRadius: '3px', background: 'linear-gradient(40deg, var(--left-2), var(--right-2)'}}
              />
            <p style={{fontSize: '1.33em'}}><a href='mailto:info@dennet.io' style={{color: 'black', textDecoration: 'underline'}}>info@dennet.io</a></p>
            <p style={{fontSize: '1.33em'}}>+31643535926</p>
          </Segment>
        </Modal.Content>
      </Modal>
    </div>
  );
}

export default ContactModal;
