import React, {Component} from 'react'
import {createMedia} from '@artsy/fresnel'
import PropTypes from 'prop-types'
import 'semantic-ui-css/semantic.min.css';
import {
    Icon,
    Image,
    Menu,
    Segment,
    Sidebar,
} from 'semantic-ui-react'
import {Link, NavLink} from 'react-router-dom';
import HomepageHeading from '../../Home/components/HomepageHeading'
import ContactModal from '../../../components/Contact'
import styles from '../DennetMenu.module.css'

const {Media} = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

class MobileContainer extends Component {
    state = {}

    handleSidebarHide = () => this.setState({sidebarOpened: false})

    handleToggle = () => this.setState({sidebarOpened: true})

    render() {
        const {children, location} = this.props
        const {sidebarOpened} = this.state

        const isActive = (path) => location.pathname === path;
        return (
            <Media as={Sidebar.Pushable} at='mobile'>
                <Sidebar.Pushable>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        onHide={this.handleSidebarHide}
                        vertical
                        visible={sidebarOpened}
                        style={{background: 'var(--light-grey)'}}
                    >
                        <Menu.Item as={Link} to='/' active={isActive('/')}>Home</Menu.Item>
                        <Menu.Item as={NavLink} to='/portfolio' active={isActive('/portfolio')}>Portfolio</Menu.Item>
                        <Menu.Item as={NavLink} to='/interim' active={isActive('/interim')}>Interim</Menu.Item>
                        <Menu.Item as={NavLink} to='/iot' active={isActive('/iot')}>IoT</Menu.Item>
                        <Menu.Item as={NavLink} to='/data' active={isActive('/data')}>Data</Menu.Item>
                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarOpened}>
                        <Segment
                            textAlign='center'
                            style={{background: `linear-gradient(180deg, var(--light-grey), #fff)`, padding: '0em 0em'}}
                            vertical
                            basic
                        >
                            <Menu borderless size='medium'>
                                <Menu.Item onClick={this.handleToggle} style={{paddingBottom: '1.5em'}}>
                                    <Icon name='sidebar'/>
                                </Menu.Item>
                                <Menu.Item as={Link} to='/'>
                                    <Image src='img/dennet-black.svg'
                                           alt='Dennet logo by b9'
                                           className={styles.dennetLogo}
                                    />
                                </Menu.Item>
                                <Menu.Item position='right'>
                                    <ContactModal/>
                                </Menu.Item>
                            </Menu>
                            {isActive('/') ? <HomepageHeading mobile/> : null}
                        </Segment>
                        {children}
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Media>
        )
    }
}

MobileContainer.propTypes = {
    children: PropTypes.node,
}


export default MobileContainer
