import {Grid, Header, Image, Segment, Icon} from "semantic-ui-react";
import PathDrawingAnimation from "./MotionTree";
import styles from "./Components.module.css";
import React from "react";
import {createMedia} from "@artsy/fresnel";

const {Media, MediaContextProvider} = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

function UnderConstruction() {
    const [hasScrolled, setHasScrolled] = React.useState(false);

    React.useEffect(() => {
        const onScroll = () => {
            setHasScrolled(true);
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <MediaContextProvider>

            <Segment style={{padding: '0em 0em', display: 'flex', flexDirection: 'column', alignItems: 'center'}}
                     vertical>
                <Media greaterThan='mobile'>
                    <Icon name='arrow down' size='huge' className={hasScrolled ? '' : styles.arrowBounce}/>
                    <PathDrawingAnimation height='90vh'/>
                </Media>
                <Media at='mobile'>
                    <Image src='img/Den-gradient.svg'
                           style={{height: '90vh', display: 'inline-block', paddingBottom: '2em'}}/>
                </Media>
            </Segment>

            <Segment style={{padding: '8em 0em', backgroundColor: '#FFFFF'}} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header as='h3' style={{fontSize: '3em'}}>
                                Page under
                                <span className={styles.lineargradient}> construction.</span>
                            </Header>
                            <p style={{fontSize: '1.33em'}}>
                                Dennet ontwerpt bedrijfssoftware en technische producten die naadloos integreren en
                                met een diepe focus op de gebruikersbehoeften zijn ontworpen.
                            </p>
                            <p style={{fontSize: '1.33em'}}>
                                Innovatie waarbij elke interactie natuurlijk aanvoelt en bescherming van persoonlijke gegevens als
                                prioriteit wordt beschouwd.
                            </p>
                        </Grid.Column>
                        <Grid.Column floated='right' width={6}>
                            <Image rounded size='large' src='img/20188201_4200_3_02.svg'/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </MediaContextProvider>
    );
}

export default UnderConstruction