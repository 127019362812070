import {Accordion, Header, Icon} from "semantic-ui-react";
import React, {Component} from "react";
import '../interim.css'

class AccordionProcessSubStep extends Component {
    state = {activeIndex: -1};

    handleClick = (e, titleProps) => {
        const {index} = titleProps;
        const {activeIndex} = this.state;
        const newIndex = activeIndex === index ? -1 : index;

        this.setState({activeIndex: newIndex});
    };

    render() {
        const {activeIndex} = this.state;
        const {info} = this.props;

        return (
            <Accordion style={{maxWidth: '95%', marginTop: '0.5em'}}>
                <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.handleClick}
                >
                    <Header as={'h3'}>
                        <Icon name={activeIndex === 0 ? 'angle down' : 'angle right'}/>
                        {info.title}
                    </Header>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0} style={{padding: '0em 3em', textAlign: 'justify', textJustify: 'inter-word'}}>
                    {info.subtitle}
                </Accordion.Content>
            </Accordion>
        );
    }
}

export default AccordionProcessSubStep;