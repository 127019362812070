import React, {useState, useEffect} from 'react';
import {motion, useScroll, useTransform} from 'framer-motion';

const transition = {
    duration: 0.1,
    ease: "linear",
};


function PathDrawingAnimation({height}) {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleWindowResize);

        // Cleanup function to remove the event listener when the component unmounts.
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const pathStart = width >= 768 ? 0.01 : 0.05;
    const pathEnd = width >= 768 ? 0.40 : 0.40;
    const opacityStart = width >= 768 ? 0.39 : 0.29;
    const opacityEnd = width >= 768 ? 0.43 : 0.31;

    // useScroll gives us the scroll progress
    const {scrollYProgress} = useScroll();

    // Use the scrollYProgress to animate the pathLength from 0 to 1
    const pathLength = useTransform(scrollYProgress, [pathStart, pathEnd], [0, 1]);
    const fillOpacity = useTransform(scrollYProgress, [opacityStart, opacityEnd], [0, 1]); // Smoothly transition opacity based on scroll

    return (
        <div style={{height: '180vh'}}>
            <svg viewBox="0 0 1849 1563" height={height} style={{position: 'sticky', top: '5vh', maxWidth: '100%'}}>

                <motion.path
                    d="M1611.19,1342.13C1615.86,1346.83 1615.84,1354.44 1611.14,1359.11C1606.44,1363.78 1598.84,1363.75 1594.17,1359.06C1589.5,1354.36 1589.52,1346.75 1594.22,1342.08C1598.92,1337.41 1606.52,1337.44 1611.19,1342.13Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1708.17,1297.1C1714.79,1297.09 1720.17,1302.47 1720.18,1309.09C1720.18,1315.71 1714.81,1321.09 1708.19,1321.1C1701.56,1321.1 1696.18,1315.73 1696.18,1309.11C1696.17,1302.48 1701.55,1297.1 1708.17,1297.1Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1609.41,1235.11C1614.1,1230.42 1621.7,1230.43 1626.38,1235.11C1631.06,1239.8 1631.06,1247.4 1626.38,1252.08C1621.69,1256.77 1614.09,1256.76 1609.4,1252.08C1604.72,1247.39 1604.73,1239.79 1609.41,1235.11Z"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1388.51,1238.55C1393.18,1233.86 1400.79,1233.85 1405.48,1238.53C1410.17,1243.21 1410.17,1250.81 1405.5,1255.5C1400.82,1260.19 1393.21,1260.19 1388.53,1255.52C1383.84,1250.84 1383.83,1243.23 1388.51,1238.55Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1461.68,1372.05C1466.36,1376.73 1466.35,1384.34 1461.66,1389.02C1456.97,1393.7 1449.37,1393.69 1444.69,1389C1440.01,1384.32 1440.02,1376.71 1444.7,1372.03C1449.39,1367.35 1457,1367.36 1461.68,1372.05Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1021.58,1457.79L1021.57,1435.91L1172.84,1284.79L1448.59,1284.83L1483.96,1320.16L1572.48,1320.21C1572.48,1320.21 1586.46,1334.28 1597.6,1345.49M1448.59,1284.83L1462.83,1270.49L1590.98,1270.49L1629.7,1309.15C1629.7,1309.15 1674.42,1309.12 1700.98,1309.1M1590.98,1270.49C1590.98,1270.49 1600.21,1261.14 1610.2,1251.16M1359.28,1284.82C1359.28,1284.82 1378.29,1265.78 1391.91,1252.12M1285.65,1284.85L1333.89,1333.14L1405.81,1333.06C1405.81,1333.06 1431.56,1358.87 1448.1,1375.43"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1696.88,1207.1C1701.55,1211.79 1701.54,1219.39 1696.85,1224.07C1692.16,1228.75 1684.56,1228.74 1679.88,1224.05C1675.21,1219.36 1675.22,1211.76 1679.91,1207.08C1684.6,1202.4 1692.2,1202.41 1696.88,1207.1Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1554.35,1200.36C1560.97,1200.35 1566.35,1205.73 1566.35,1212.35C1566.36,1218.97 1560.98,1224.35 1554.36,1224.36C1547.74,1224.36 1542.36,1218.99 1542.35,1212.36C1542.35,1205.74 1547.73,1200.36 1554.35,1200.36Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1685.57,1115.22C1690.24,1110.53 1697.85,1110.52 1702.54,1115.19C1707.23,1119.87 1707.24,1127.47 1702.57,1132.16C1697.89,1136.85 1690.29,1136.87 1685.6,1132.19C1680.91,1127.52 1680.89,1119.91 1685.57,1115.22Z"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1014.96,1475.3L1014.94,1430.51L1217.56,1227.85L1366.59,1227.81L1421.87,1172.36L1645.42,1172.51C1645.42,1172.51 1668.06,1195.2 1683.3,1210.48M1421.87,1172.36L1461.91,1212.4C1461.91,1212.4 1516.99,1212.37 1547.15,1212.36M1645.42,1172.51C1645.42,1172.51 1669.7,1148.04 1686.59,1131.09"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1696.53,1042.97C1701.21,1038.29 1708.82,1038.29 1713.5,1042.98C1718.18,1047.66 1718.17,1055.27 1713.49,1059.95C1708.81,1064.63 1701.2,1064.62 1696.52,1059.94C1691.84,1055.25 1691.84,1047.65 1696.53,1042.97Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1588.83,1131.29C1593.52,1135.97 1593.53,1143.58 1588.85,1148.26C1584.17,1152.95 1576.57,1152.96 1571.88,1148.28C1567.19,1143.6 1567.18,1136 1571.86,1131.31C1576.54,1126.62 1584.14,1126.61 1588.83,1131.29Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1616.89,1049.66C1623.51,1049.65 1628.89,1055.03 1628.89,1061.65C1628.9,1068.27 1623.52,1073.65 1616.9,1073.66C1610.28,1073.66 1604.9,1068.29 1604.89,1061.66C1604.89,1055.04 1610.26,1049.66 1616.89,1049.66Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1008.19,1496.83L1008.23,1424.85L1266.74,1166.34L1353.37,1166.35L1423.84,1095.86L1660.54,1095.89C1660.54,1095.89 1684.22,1072.22 1699.91,1056.55M1536.35,1095.87C1536.35,1095.87 1559.7,1119.18 1575.26,1134.7M1478.58,1095.87L1512.82,1061.71C1512.82,1061.71 1576.72,1061.68 1609.69,1061.66"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1708.41,911.415C1713.09,906.731 1720.69,906.731 1725.38,911.414C1730.06,916.096 1730.06,923.701 1725.38,928.384C1720.7,933.068 1713.09,933.068 1708.41,928.386C1703.72,923.703 1703.72,916.098 1708.41,911.415Z"
                    stroke='rgb(115,27,190)'
                    fill='rgb(115,27,190)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1651.16,896.043C1651.17,889.42 1656.56,884.05 1663.18,884.058C1669.8,884.067 1675.17,889.45 1675.16,896.073C1675.16,902.696 1669.77,908.066 1663.15,908.058C1656.53,908.05 1651.16,902.666 1651.16,896.043Z"
                    stroke='rgb(115,27,190)'
                    fill='rgb(115,27,190)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1556.62,919.787C1556.63,913.164 1562.01,907.794 1568.63,907.802C1575.26,907.81 1580.63,913.194 1580.62,919.817C1580.61,926.44 1575.23,931.81 1568.6,931.802C1561.98,931.794 1556.61,926.41 1556.62,919.787Z"
                    stroke='rgb(115,27,190)'
                    fill='rgb(115,27,190)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1612.93,793.353C1617.61,788.667 1625.22,788.664 1629.9,793.344C1634.59,798.025 1634.59,805.629 1629.91,810.315C1625.23,815 1617.62,815.004 1612.94,810.323C1608.25,805.643 1608.25,798.038 1612.93,793.353Z"
                    fill="none"
                    stroke='rgb(115,27,190)'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1697.98,793.524C1702.66,788.834 1710.26,788.824 1714.95,793.5C1719.64,798.177 1719.65,805.781 1714.98,810.471C1710.3,815.16 1702.69,815.171 1698,810.494C1693.32,805.818 1693.3,798.213 1697.98,793.524Z"
                    stroke='rgb(115,27,190)'
                    fill=' rgb(115,27,190)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1398.5,951.988C1398.5,945.365 1403.88,939.99 1410.5,939.992C1417.12,939.994 1422.5,945.372 1422.5,951.995C1422.49,958.618 1417.11,963.994 1410.49,963.992C1403.87,963.99 1398.49,958.611 1398.5,951.988Z"
                    stroke='rgb(115,27,190)'
                    fill='rgb(115,27,190)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1001.48,1488.37L1001.48,1419.33L1312.01,1108.77L1361.6,1108.83L1447.05,1023.36L1613.37,1023.44C1613.37,1023.44 1681.98,954.82 1711.8,924.991M1663.07,973.732C1663.07,973.732 1663.12,929.603 1663.16,903.258M1514.76,1023.39L1568.56,969.571C1568.56,969.571 1568.59,945.135 1568.61,927.002M1447.05,1023.36L1447.16,976.27C1447.16,976.27 1571.7,851.504 1614.03,809.127M1566.13,857.18L1651.46,857.168C1651.46,857.168 1682.75,825.796 1701.39,807.095M1361.6,1108.83L1361.59,1039.58L1410.48,990.62C1410.48,990.62 1410.49,973.516 1410.49,959.192"
                    fill="none"
                    stroke='rgb(115,27,190)'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1470.86,846.363C1475.54,841.681 1483.14,841.683 1487.83,846.368C1492.51,851.052 1492.51,858.656 1487.82,863.338C1483.14,868.02 1475.53,868.018 1470.85,863.334C1466.17,858.649 1466.17,851.045 1470.86,846.363Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1605.89,615.468C1610.57,610.783 1618.17,610.781 1622.86,615.463C1627.54,620.144 1627.55,627.749 1622.86,632.433C1618.18,637.118 1610.58,637.12 1605.89,632.438C1601.21,627.757 1601.21,620.152 1605.89,615.468Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1605.89,615.468C1610.57,610.783 1618.17,610.781 1622.86,615.463C1627.54,620.144 1627.55,627.749 1622.86,632.433C1618.18,637.118 1610.58,637.12 1605.89,632.438C1601.21,627.757 1601.21,620.152 1605.89,615.468Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                    initial={{translateX: -1054.8, translateY: 57.1321}}
                />

                <motion.path
                    d="M1348.47,837.167C1343.79,832.48 1343.79,824.876 1348.48,820.196C1353.17,815.516 1360.77,815.521 1365.45,820.207C1370.13,824.893 1370.13,832.498 1365.44,837.178C1360.75,841.858 1353.15,841.853 1348.47,837.167Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1563.91,763.376C1568.53,758.631 1576.13,758.531 1580.88,763.152C1585.62,767.774 1585.72,775.377 1581.1,780.122C1576.48,784.866 1568.87,784.966 1564.13,780.345C1559.39,775.724 1559.29,768.12 1563.91,763.376Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1463.98,699.987C1463.99,693.364 1469.37,687.994 1476,688.002C1482.62,688.011 1487.99,693.395 1487.98,700.018C1487.97,706.641 1482.59,712.011 1475.97,712.002C1469.34,711.994 1463.97,706.61 1463.98,699.987Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1610.6,669.403C1615.26,664.7 1622.86,664.667 1627.57,669.33C1632.27,673.993 1632.3,681.597 1627.64,686.3C1622.98,691.004 1615.37,691.036 1610.67,686.373C1605.97,681.71 1605.93,674.106 1610.6,669.403Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M994.765,1468.12L994.801,1413.72L1280.29,1128.22L1280.48,1053.6C1280.48,1053.6 1428.14,906.017 1474.25,859.941M1405.76,928.393L1405.74,832.708C1405.74,832.708 1561.77,676.593 1609.29,629.043M1405.75,877.54C1405.75,877.54 1378.98,850.739 1362.05,833.781M1446.94,791.489L1553.3,791.465C1553.3,791.465 1560.46,784.116 1567.48,776.906M1475.9,762.506C1475.9,762.506 1475.94,729.277 1475.97,707.202M1525.46,712.919L1584.34,712.928C1584.34,712.928 1601.37,695.755 1614.05,682.965"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1376.43,353.512C1381.14,348.856 1388.75,348.899 1393.41,353.609C1398.06,358.319 1398.02,365.923 1393.31,370.579C1388.6,375.236 1381,375.192 1376.34,370.482C1371.68,365.772 1371.73,358.168 1376.43,353.512Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1267.17,784.763C1267.17,778.14 1272.55,772.764 1279.18,772.766C1285.8,772.767 1291.17,778.145 1291.17,784.768C1291.17,791.391 1285.8,796.767 1279.17,796.766C1272.55,796.764 1267.17,791.386 1267.17,784.763Z"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1267.17,784.763C1267.17,778.14 1272.55,772.764 1279.18,772.766C1285.8,772.767 1291.17,778.145 1291.17,784.768C1291.17,791.391 1285.8,796.767 1279.17,796.766C1272.55,796.764 1267.17,791.386 1267.17,784.763Z"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                    initial={{translateX: -259.847, translateY: 45.2425}}
                />

                <motion.path
                    d="M1267.17,784.763C1267.17,778.14 1272.55,772.764 1279.18,772.766C1285.8,772.767 1291.17,778.145 1291.17,784.768C1291.17,791.391 1285.8,796.767 1279.17,796.766C1272.55,796.764 1267.17,791.386 1267.17,784.763Z"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                    initial={{translateX: -524.751, translateY: -268.875}}
                />

                <motion.path
                    d="M1569.04,544.084C1573.73,539.405 1581.33,539.412 1586.01,544.1C1590.69,548.787 1590.68,556.391 1585.99,561.07C1581.31,565.749 1573.7,565.742 1569.02,561.055C1564.34,556.367 1564.35,548.763 1569.04,544.084Z"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1569.04,544.084C1573.73,539.405 1581.33,539.412 1586.01,544.1C1590.69,548.787 1590.68,556.391 1585.99,561.07C1581.31,565.749 1573.7,565.742 1569.02,561.055C1564.34,556.367 1564.35,548.763 1569.04,544.084Z"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                    initial={{translateX: -155.202, translateY: -141.111}}
                />

                <motion.path
                    d="M1569.04,544.084C1573.73,539.405 1581.33,539.412 1586.01,544.1C1590.69,548.787 1590.68,556.391 1585.99,561.07C1581.31,565.749 1573.7,565.742 1569.02,561.055C1564.34,556.367 1564.35,548.763 1569.04,544.084Z"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                    initial={{translateX: -454.592, translateY: -42.992}}
                />

                <motion.path
                    d="M1375.51,564.732C1375.5,558.109 1380.87,552.722 1387.49,552.71C1394.11,552.698 1399.5,558.065 1399.51,564.688C1399.52,571.311 1394.15,576.698 1387.53,576.71C1380.91,576.722 1375.52,571.355 1375.51,564.732Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1459.69,555.406C1459.69,548.783 1465.07,543.406 1471.69,543.406C1478.32,543.405 1483.69,548.782 1483.69,555.405C1483.69,562.028 1478.32,567.405 1471.7,567.406C1465.07,567.406 1459.69,562.029 1459.69,555.406Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1520.14,471.554C1520.12,464.931 1525.48,459.536 1532.1,459.515C1538.73,459.493 1544.12,464.853 1544.14,471.476C1544.16,478.099 1538.81,483.493 1532.18,483.515C1525.56,483.536 1520.16,478.177 1520.14,471.554Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1520.14,471.554C1520.12,464.931 1525.48,459.536 1532.1,459.515C1538.73,459.493 1544.12,464.853 1544.14,471.476C1544.16,478.099 1538.81,483.493 1532.18,483.515C1525.56,483.536 1520.16,478.177 1520.14,471.554Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                    initial={{translateX: -67.594, translateY: -1.7068}}
                />

                <motion.path
                    d="M971.506,1473.4L971.532,1418.63L1212.54,1177.46L1212.54,1071.87L1317.61,966.855L1317.55,754.614L1354.69,717.496L1354.71,391.859C1354.71,391.859 1368.62,378.118 1379.75,367.108M1317.59,914.019L1279.16,875.333C1279.16,875.333 1278.98,825.365 1278.98,795.673M1317.57,812.046C1317.57,812.046 1515.49,614.171 1570.06,559.702M1424.26,705.547L1387.7,668.992C1387.7,668.992 1387.58,604.937 1387.52,571.91M1471.7,658.198C1471.7,658.198 1471.7,595.28 1471.69,562.606M1532.55,597.458C1532.55,597.458 1532.29,516.626 1532.17,478.715"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path d="M1424.26,705.547L1422.49,422.642"
                             fill="none"
                             stroke="black"
                             strokeWidth="4"
                             style={{pathLength}}
                             transition={transition}
                />

                <motion.path d="M1423.32,555.232L1464.77,513.364L1465.08,464.958"
                             fill="none"
                             stroke="black"
                             strokeWidth="4"
                             style={{pathLength}}
                             transition={transition}
                />

                <motion.path
                    d="M1316.61,301.082C1316.62,294.459 1322,289.086 1328.62,289.091C1335.24,289.096 1340.62,294.477 1340.61,301.1C1340.61,307.723 1335.23,313.096 1328.6,313.091C1321.98,313.086 1316.61,307.705 1316.61,301.082Z"
                    stroke='rgb(154,27,142)'
                    fill='rgb(154,27,142)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1204.48,600.142C1204.48,593.519 1209.86,588.142 1216.48,588.142C1223.1,588.142 1228.48,593.519 1228.48,600.142C1228.48,606.765 1223.1,612.142 1216.48,612.142C1209.86,612.142 1204.48,606.765 1204.48,600.142Z"
                    stroke='rgb(154,27,142)'
                    fill='rgb(154,27,142)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1224.74,511.505C1220.06,506.824 1220.05,499.22 1224.73,494.534C1229.41,489.848 1237.02,489.843 1241.7,494.524C1246.39,499.204 1246.39,506.808 1241.71,511.494C1237.04,516.18 1229.43,516.185 1224.74,511.505Z"
                    stroke='rgb(154,27,142)'
                    fill='rgb(154,27,142)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1239.47,385.384C1234.78,380.699 1234.79,373.095 1239.47,368.413C1244.16,363.732 1251.76,363.734 1256.44,368.419C1261.12,373.103 1261.12,380.708 1256.44,385.389C1251.75,390.071 1244.15,390.068 1239.47,385.384Z"
                    stroke='rgb(154,27,142)'
                    fill='rgb(154,27,142)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M964.805,1479.03L964.788,1413.03L1132.2,1245.62L1132.16,1186.22L1188.96,1129.34L1188.97,1036.89L1249.57,976.174L1249.63,705.623L1300.54,654.739L1300.48,570.188L1328.43,542.259C1328.43,542.259 1328.56,367.336 1328.61,308.291M1249.63,705.623L1216.48,672.476L1216.48,607.342M1300.48,570.188C1300.48,570.188 1260.12,529.873 1238.32,508.102M1328.49,457.493C1328.49,457.493 1277.97,406.932 1253.04,381.994"
                    stroke='rgb(154,27,142)'
                    fill="none"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1186.84,741.077C1186.81,734.454 1192.17,729.058 1198.8,729.035C1205.42,729.012 1210.81,734.37 1210.84,740.993C1210.86,747.616 1205.5,753.012 1198.88,753.035C1192.26,753.058 1186.86,747.7 1186.84,741.077Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1165.83,868.116C1165.86,861.493 1171.26,856.143 1177.88,856.175C1184.51,856.207 1189.86,861.61 1189.83,868.233C1189.79,874.856 1184.39,880.207 1177.77,880.175C1171.14,880.142 1165.79,874.739 1165.83,868.116Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1149.48,686.573C1149.49,679.95 1154.88,674.58 1161.5,674.589C1168.12,674.598 1173.49,679.982 1173.48,686.605C1173.47,693.228 1168.09,698.598 1161.47,698.589C1154.84,698.58 1149.47,693.196 1149.48,686.573Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M958.078,1500.19L958.091,1407.41L1084.02,1281.43L1084.08,1127.48L1145.05,1066.33L1145.05,854.123L1199.05,800.062C1199.05,800.062 1198.94,769.27 1198.86,748.235M1145.05,951.646L1177.58,918.891C1177.58,918.891 1177.7,893.814 1177.79,875.375M1199.05,800.062L1161.38,762.231C1161.38,762.231 1161.44,719.584 1161.47,693.789"
                    stroke='black'
                    fill="none"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1165.01,984.912C1160.51,989.416 1160.51,996.731 1165.01,1001.24C1169.51,1005.74 1176.83,1005.74 1181.34,1001.24C1185.84,996.737 1185.84,989.422 1181.34,984.916C1176.84,980.41 1169.52,980.408 1165.01,984.912Z"
                    stroke='black'
                    fill="none"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1197.01,839.084C1197.03,832.461 1202.41,827.095 1209.04,827.109C1215.66,827.123 1221.03,832.511 1221.01,839.134C1221,845.757 1215.61,851.123 1208.99,851.109C1202.36,851.095 1197,845.707 1197.01,839.084Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1181.34,984.916C1193.99,972.273 1208.76,957.505 1208.76,957.505C1208.76,957.505 1208.92,882.566 1209,846.309"
                    stroke='black'
                    fill="none"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1059.89,1059.99C1055.21,1064.68 1055.22,1072.28 1059.91,1076.96C1064.6,1081.64 1072.2,1081.63 1076.88,1076.94C1081.56,1072.25 1081.55,1064.65 1076.86,1059.97C1072.17,1055.29 1064.57,1055.3 1059.89,1059.99Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1108.54,1011.22C1113.22,1006.53 1120.83,1006.52 1125.52,1011.2C1130.2,1015.87 1130.21,1023.48 1125.54,1028.17C1120.86,1032.86 1113.25,1032.86 1108.56,1028.19C1103.88,1023.51 1103.87,1015.9 1108.54,1011.22Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path d="M1073.47,1063.37C1084.74,1052.07 1100.69,1036.08 1111.95,1024.79"
                             fill="none"
                             stroke="black"
                             strokeWidth="4"
                             style={{pathLength}}
                             transition={transition}
                />

                <motion.path
                    d="M1049.9,383.134C1049.9,376.511 1055.28,371.133 1061.9,371.132C1068.52,371.131 1073.9,376.507 1073.9,383.13C1073.9,389.753 1068.53,395.131 1061.9,395.132C1055.28,395.133 1049.9,389.757 1049.9,383.134Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1172.09,209.063C1172.08,202.44 1177.45,197.058 1184.08,197.052C1190.7,197.046 1196.08,202.418 1196.09,209.041C1196.09,215.664 1190.72,221.046 1184.1,221.052C1177.48,221.058 1172.09,215.686 1172.09,209.063Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1224.79,316.611C1229.47,311.933 1237.08,311.941 1241.76,316.629C1246.43,321.317 1246.43,328.921 1241.74,333.599C1237.05,338.278 1229.45,338.27 1224.77,333.582C1220.09,328.894 1220.1,321.289 1224.79,316.611Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1227.81,234.453C1232.49,229.773 1240.1,229.779 1244.78,234.466C1249.46,239.152 1249.45,246.756 1244.77,251.436C1240.08,256.116 1232.48,256.11 1227.8,251.423C1223.12,246.737 1223.12,239.133 1227.81,234.453Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1115.77,178.386C1115.77,171.763 1121.14,166.385 1127.77,166.384C1134.39,166.383 1139.77,171.759 1139.77,178.382C1139.77,185.005 1134.39,190.383 1127.77,190.384C1121.15,190.385 1115.77,185.009 1115.77,178.386Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1066.49,216.569C1066.49,209.946 1071.87,204.572 1078.5,204.576C1085.12,204.58 1090.49,209.96 1090.49,216.583C1090.48,223.206 1085.1,228.58 1078.48,228.576C1071.86,228.572 1066.48,223.192 1066.49,216.569Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M951.387,1483.75L951.377,1401.85L996.15,1357.11L996.187,1264.49L1046.52,1214.12L1046.48,1025.4L1095.27,976.601L1095.25,623.352L1061.94,589.983C1061.94,589.983 1061.91,443.711 1061.9,390.332M1095.26,787.362L1126.31,756.266L1126.35,677.521L1184.47,619.339C1184.47,619.339 1184.17,298.797 1184.09,216.252M1184.24,374.025C1184.24,374.025 1211.16,347.162 1228.16,330.191M1184.17,294.987C1184.17,294.987 1213.32,265.874 1231.19,248.032M1184.35,488.278L1127.82,431.684C1127.82,431.684 1127.78,246.538 1127.77,185.584M1127.8,329.623L1078.45,280.248C1078.45,280.248 1078.47,246.195 1078.48,223.776M1095.25,623.352L1122.85,595.688C1122.85,595.688 1122.9,549.262 1122.92,522.084"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1056.22,853.891C1056.22,847.268 1061.59,841.889 1068.22,841.887C1074.84,841.885 1080.22,847.26 1080.22,853.883C1080.22,860.506 1074.85,865.885 1068.22,865.887C1061.6,865.889 1056.22,860.514 1056.22,853.891Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M924.037,1490.93L924.049,1373.89L951.136,1346.79L951.072,1263.94L1026.22,1188.79L1026.3,993.395L1068.26,951.296C1068.26,951.296 1068.24,892.469 1068.22,861.087"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1040.19,762.377C1040.19,755.754 1045.56,750.371 1052.18,750.364C1058.8,750.356 1064.19,755.727 1064.19,762.35C1064.2,768.973 1058.83,774.356 1052.21,774.364C1045.59,774.371 1040.2,769 1040.19,762.377Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M917.319,1471.37L917.361,1368.3L944.423,1341.25L944.428,1195.37L996.891,1142.93L997.029,976.269L1052.38,920.689C1052.38,920.689 1052.25,814.104 1052.2,769.564M1052.38,920.689L1019.37,887.94C1019.37,887.94 1019.42,860.367 1019.45,840.764"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M948.828,471.358C948.829,464.735 954.206,459.359 960.829,459.36C967.452,459.361 972.829,464.738 972.828,471.361C972.827,477.984 967.45,483.361 960.827,483.36C954.204,483.359 948.827,477.981 948.828,471.358Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1002.79,469.265C1002.8,462.642 1008.18,457.272 1014.8,457.282C1021.43,457.291 1026.8,462.676 1026.79,469.299C1026.78,475.922 1021.39,481.291 1014.77,481.282C1008.15,481.272 1002.78,475.888 1002.79,469.265Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M982.622,553.341C982.622,546.718 988,541.342 994.623,541.343C1001.25,541.344 1006.62,546.722 1006.62,553.345C1006.62,559.967 1001.24,565.344 994.62,565.343C987.997,565.342 982.621,559.964 982.622,553.341Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M890.475,1472.69L890.496,1267.08L916.478,1240.99L916.59,1189.9L945.367,1161L945.444,1085.78L976.97,1054.1L976.98,832.404L1000.76,808.547L1000.88,711.6L960.804,671.57C960.804,671.57 960.821,530.792 960.827,478.56M1000.84,745.225L1041.09,704.85L1041.05,532.42L1014.73,505.973C1014.73,505.973 1014.76,490.102 1014.77,476.482M960.805,660.408L994.612,626.564C994.612,626.564 994.617,585.675 994.621,560.543"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M1014.95,246.372C1014.95,239.749 1020.34,234.376 1026.96,234.38C1033.58,234.385 1038.95,239.766 1038.95,246.389C1038.94,253.012 1033.56,258.385 1026.94,258.38C1020.32,258.376 1014.94,252.995 1014.95,246.372Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M965.865,278.376C965.869,271.753 971.25,266.38 977.873,266.384C984.496,266.388 989.869,271.768 989.865,278.391C989.861,285.014 984.481,290.388 977.858,290.384C971.235,290.38 965.861,284.999 965.865,278.376Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M917.603,308.492C917.618,301.869 923.007,296.504 929.63,296.519C936.253,296.534 941.618,301.924 941.603,308.547C941.587,315.17 936.198,320.534 929.575,320.519C922.952,320.504 917.588,315.115 917.603,308.492Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M977.784,413.093L1026.87,364.122C1026.87,364.122 1026.92,289.691 1026.94,253.58M977.784,413.093C977.784,413.093 977.837,325.36 977.861,285.584M979.186,414.488L929.475,364.704C929.475,364.704 929.54,335.885 929.586,315.719"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M975.019,104.796C975.024,98.173 980.405,92.8 987.028,92.805C993.651,92.81 999.024,98.191 999.019,104.814C999.014,111.437 993.633,116.81 987.01,116.805C980.387,116.8 975.014,111.419 975.019,104.796Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M842.895,204.496C842.896,197.873 848.273,192.497 854.896,192.498C861.519,192.499 866.896,197.876 866.895,204.499C866.894,211.122 861.516,216.499 854.893,216.498C848.27,216.497 842.894,211.119 842.895,204.496Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M920.304,142.603C920.31,135.98 925.692,130.608 932.315,130.614C938.938,130.621 944.31,136.003 944.304,142.626C944.298,149.249 938.916,154.621 932.293,154.614C925.67,154.608 920.298,149.226 920.304,142.603Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M884.095,299.955L986.95,196.894C986.95,196.894 986.991,142.078 987.014,112.005M885.528,298.536L854.887,267.901C854.887,267.901 854.891,234.038 854.894,211.698M932.202,251.752C932.202,251.752 932.265,183.974 932.297,149.814"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M942.807,792.288C942.82,798.911 948.206,804.278 954.829,804.266C961.452,804.254 966.82,798.867 966.807,792.244C966.795,785.621 961.408,780.254 954.785,780.266C948.162,780.278 942.795,785.665 942.807,792.288Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M922.869,633.65C922.875,627.027 928.256,621.655 934.879,621.66C941.502,621.666 946.875,627.048 946.869,633.671C946.863,640.294 941.482,645.666 934.859,645.66C928.236,645.655 922.863,640.273 922.869,633.65Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M954.794,785.066C954.763,767.852 954.721,745.29 954.721,745.29L934.791,725.254C934.791,725.254 934.837,670.81 934.863,640.86"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M854.929,747.868C850.235,752.54 850.218,760.144 854.89,764.839C859.562,769.533 867.166,769.55 871.86,764.878C876.554,760.206 876.572,752.601 871.9,747.907C867.227,743.213 859.623,743.196 854.929,747.868Z"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M847.045,654.357C842.351,649.684 842.334,642.08 847.007,637.386C851.679,632.692 859.284,632.675 863.977,637.348C868.671,642.021 868.688,649.625 864.015,654.319C859.343,659.012 851.738,659.029 847.045,654.357Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M871.9,747.907C879.385,740.456 886.305,733.569 886.305,733.569L886.29,676.494C886.29,676.494 871.963,662.231 860.614,650.932"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M863.582,859.652C858.904,854.963 858.913,847.359 863.602,842.681C868.291,838.003 875.895,838.013 880.573,842.701C885.25,847.39 885.241,854.994 880.552,859.672C875.863,864.349 868.259,864.34 863.582,859.652Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M928.361,1038.58C933.073,1033.93 940.677,1033.98 945.331,1038.69C949.986,1043.4 949.939,1051 945.227,1055.66C940.515,1060.31 932.911,1060.27 928.257,1055.55C923.603,1050.84 923.649,1043.24 928.361,1038.58Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M858.641,956.371C853.968,951.677 853.986,944.073 858.68,939.401C863.374,934.728 870.979,934.746 875.651,939.44C880.323,944.135 880.305,951.739 875.611,956.411C870.917,961.083 863.313,961.065 858.641,956.371Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M894.929,808.547C890.257,803.853 890.274,796.248 894.968,791.576C899.662,786.904 907.266,786.921 911.939,791.615C916.611,796.309 916.594,803.913 911.9,808.586C907.206,813.258 899.601,813.24 894.929,808.547Z"
                    stroke="black"
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M883.798,1463.85L883.777,1171.99L912.326,1143.52L912.321,891.517C912.321,891.517 891.578,870.724 877.162,856.274M912.325,1071.29C912.325,1071.29 922.591,1061.15 931.672,1052.18M912.323,993.295C912.323,993.295 888.116,968.975 872.225,953.009M912.323,993.295L941.158,964.474L941.087,837.908C941.087,837.908 922.12,818.854 908.513,805.184"
                    fill="none"
                    stroke="black"
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M744.322,207.688C739.64,203.003 739.642,195.399 744.327,190.717C749.011,186.035 756.615,186.037 761.297,190.722C765.979,195.406 765.977,203.01 761.293,207.692C756.608,212.374 749.004,212.372 744.322,207.688Z"
                    stroke='rgb(185,28,106)'
                    fill='rgb(185,28,106)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M872.784,566.61C872.784,559.987 878.161,554.61 884.784,554.61C891.407,554.61 896.784,559.988 896.784,566.611C896.784,573.234 891.406,578.61 884.783,578.61C878.16,578.61 872.784,573.233 872.784,566.61Z"
                    stroke='rgb(185,28,106)'
                    fill='rgb(185,28,106)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M1569.04,544.084C1573.73,539.405 1581.33,539.412 1586.01,544.1C1590.69,548.787 1590.68,556.391 1585.99,561.07C1581.31,565.749 1573.7,565.742 1569.02,561.055C1564.34,556.367 1564.35,548.763 1569.04,544.084Z"
                    fill="none"
                    stroke='rgb(185,28,106)'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                    initial={{translateX: -648.043, translateY: -120.801}}
                />

                <motion.path
                    d="M866.156,356.216C870.837,351.53 878.441,351.525 883.127,356.206C887.813,360.886 887.817,368.491 883.137,373.176C878.456,377.862 870.852,377.867 866.166,373.186C861.48,368.506 861.476,360.902 866.156,356.216Z"
                    stroke='rgb(185,28,106)'
                    fill='rgb(185,28,106)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M823.238,148.583C823.233,141.96 828.606,136.579 835.229,136.575C841.852,136.57 847.233,141.943 847.238,148.566C847.242,155.189 841.869,160.57 835.246,160.575C828.623,160.579 823.242,155.206 823.238,148.583Z"
                    stroke='rgb(185,28,106)'
                    fill='rgb(185,28,106)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M877.036,1501.35L877.086,1010.07L849.192,982.116L849.251,805.474L826.864,783.17L826.842,605.44L796.534,575.068L796.5,242.919C796.5,242.919 773.358,219.764 757.899,204.297M849.251,805.474L915.157,739.508L915.039,674.069L884.781,643.848C884.781,643.848 884.782,600.038 884.784,573.81M826.842,605.44L846.872,585.531L846.731,514.774C846.731,514.774 897.3,464.604 922.581,439.306M796.52,442.913C796.52,442.913 845.188,394.189 869.558,369.79M796.51,340.827L835.347,302.423C835.347,302.423 835.274,199.439 835.243,155.775"
                    fill="none"
                    stroke='rgb(185,28,106)'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M771.716,678.387C771.701,671.764 777.065,666.374 783.688,666.358C790.311,666.343 795.701,671.707 795.716,678.33C795.732,684.953 790.368,690.343 783.745,690.358C777.122,690.374 771.732,685.01 771.716,678.387Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M710.621,350.603C710.615,343.98 715.987,338.598 722.61,338.592C729.233,338.586 734.615,343.959 734.621,350.582C734.627,357.205 729.255,362.586 722.632,362.592C716.009,362.598 710.627,357.226 710.621,350.603Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M749.137,281.498C749.126,274.875 754.494,269.488 761.117,269.477C767.74,269.466 773.126,274.834 773.137,281.457C773.149,288.079 767.781,293.466 761.158,293.477C754.535,293.488 749.149,288.12 749.137,281.498Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M870.365,1494.26L870.433,1049.29L791.595,970.245L791.677,811.153L754.411,773.768C754.411,773.768 754.538,588.086 754.562,526.743M754.411,773.768L783.874,744.49C783.874,744.49 783.789,708.692 783.734,685.558M754.462,640.712L722.855,609.051C722.855,609.051 722.683,419.546 722.627,357.792M722.713,452.625L761.364,414.222C761.364,414.222 761.217,328.041 761.15,288.677"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M742.43,887.055C742.425,893.678 747.797,899.059 754.42,899.065C761.043,899.07 766.425,893.698 766.43,887.075C766.436,880.452 761.063,875.07 754.44,875.065C747.817,875.059 742.436,880.432 742.43,887.055Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M719.767,803.784C715.088,799.097 715.096,791.492 719.783,786.814C724.471,782.135 732.075,782.143 736.754,786.83C741.432,791.518 741.425,799.122 736.737,803.801C732.05,808.479 724.445,808.472 719.767,803.784Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M754.436,879.865C754.455,856.919 754.485,821.583 754.485,821.583C754.485,821.583 743.08,810.156 733.346,800.403"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M659.92,411.603C659.917,404.98 665.292,399.601 671.915,399.598C678.538,399.595 683.917,404.97 683.92,411.593C683.923,418.216 678.548,423.595 671.925,423.598C665.302,423.601 659.923,418.226 659.92,411.603Z"
                    stroke='rgb(203,29,82'
                    fill='rgb(203,29,82)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M789.621,1015.3C789.62,1008.68 794.996,1003.3 801.619,1003.3C808.242,1003.3 813.62,1008.67 813.621,1015.3C813.623,1021.92 808.246,1027.3 801.623,1027.3C795,1027.3 789.623,1021.92 789.621,1015.3Z"
                    fill='rgb(203,29,82)'
                    stroke='rgb(203,29,82)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M750.694,951.899C750.688,945.276 756.059,939.894 762.682,939.888C769.305,939.881 774.688,945.253 774.694,951.876C774.7,958.499 769.328,963.881 762.705,963.888C756.082,963.894 750.7,958.522 750.694,951.899Z"
                    fill='rgb(203,29,82)'
                    stroke='rgb(203,29,82)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M692.336,674.761C692.337,668.138 697.714,662.762 704.337,662.762C710.96,662.763 716.337,668.14 716.336,674.763C716.335,681.386 710.958,686.763 704.335,686.762C697.712,686.762 692.335,681.384 692.336,674.761Z"
                    fill='rgb(203,29,82)'
                    stroke='rgb(203,29,82)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M692.378,930.686C692.37,924.063 697.741,918.68 704.364,918.672C710.987,918.664 716.37,924.034 716.378,930.657C716.386,937.28 711.016,942.664 704.393,942.672C697.77,942.68 692.386,937.309 692.378,930.686Z"
                    fill='rgb(203,29,82)'
                    stroke='rgb(203,29,82)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M667.452,793.502C662.764,788.824 662.755,781.22 667.433,776.531C672.11,771.842 679.715,771.834 684.403,776.511C689.092,781.189 689.101,788.793 684.423,793.482C679.745,798.171 672.141,798.179 667.452,793.502Z"
                    fill='rgb(203,29,82)'
                    stroke='rgb(203,29,82)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M610.756,507.846C610.749,501.223 616.12,495.84 622.743,495.833C629.366,495.825 634.749,501.196 634.756,507.819C634.764,514.442 629.393,519.825 622.77,519.833C616.147,519.84 610.764,514.469 610.756,507.846Z"
                    fill='rgb(203,29,82)'
                    stroke='rgb(203,29,82)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M863.746,1451.22L863.634,1188.71L656.918,981.961L656.999,719.358L672.044,704.277C672.044,704.277 671.951,485.667 671.923,418.798M801.641,1126.7C801.641,1126.7 801.629,1057.18 801.623,1022.5M801.631,1071.81L762.772,1033.08C762.772,1033.08 762.727,986.37 762.701,959.088M746.824,1071.88L746.765,930.579L704.316,888.225C704.316,888.225 704.33,736.466 704.335,681.962M690.156,1015.2L690.18,968.219L704.406,953.915C704.406,953.915 704.397,946.126 704.387,937.872M704.323,813.336C704.323,813.336 691.541,800.583 681.025,790.092M656.999,719.358L578.14,640.581C578.14,640.581 578.149,582.209 578.154,550.973M578.14,640.581L622.853,595.995C622.853,595.995 622.796,544.118 622.764,515.033"
                    fill='none'
                    stroke='rgb(203,29,82)'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M646.014,357.701C646.008,364.103 651.201,369.305 657.603,369.311C664.005,369.317 669.208,364.124 669.214,357.722C669.22,351.32 664.027,346.117 657.625,346.111C651.222,346.105 646.02,351.298 646.014,357.701Z"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M707.29,238.882C711.983,234.209 719.587,234.226 724.26,238.92C728.933,243.613 728.916,251.218 724.222,255.89C719.529,260.563 711.925,260.546 707.252,255.853C702.579,251.159 702.596,243.555 707.29,238.882Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M657.625,346.111C657.642,327.44 657.662,305.223 657.662,305.223C657.662,305.223 691.174,271.859 710.654,252.466"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M523.897,474.356C523.918,467.733 529.313,462.374 535.936,462.395C542.559,462.416 547.918,467.811 547.897,474.434C547.876,481.057 542.481,486.416 535.858,486.395C529.235,486.374 523.876,480.979 523.897,474.356Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M596.553,308.521C596.552,301.898 601.928,296.52 608.551,296.519C615.174,296.518 620.552,301.894 620.553,308.517C620.554,315.14 615.178,320.518 608.555,320.519C601.932,320.52 596.554,315.144 596.553,308.521Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M562.173,684.162L535.308,657.325C535.308,657.325 535.715,530.755 535.874,481.595M535.807,502.189L608.573,429.407C608.573,429.407 608.56,352.527 608.554,315.719"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M626.563,741.045C626.548,734.422 631.913,729.033 638.536,729.018C645.159,729.003 650.548,734.368 650.563,740.991C650.578,747.614 645.213,753.003 638.59,753.018C631.967,753.033 626.578,747.668 626.563,741.045Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M611.972,909.543C611.95,902.92 617.31,897.526 623.933,897.505C630.556,897.484 635.95,902.844 635.971,909.466C635.992,916.089 630.633,921.484 624.01,921.505C617.387,921.526 611.993,916.166 611.972,909.543Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M846.951,1476.43L846.846,1263.04L601.359,1017.42L601.258,867.793L638.764,830.285C638.764,830.285 638.645,777.583 638.579,748.218M601.359,1017.42L624.241,994.343C624.241,994.343 624.084,944.938 623.994,916.705"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M787.573,1179.06C792.1,1183.59 799.451,1183.59 803.977,1179.06C808.504,1174.53 808.503,1167.18 803.975,1162.65C799.447,1158.13 792.096,1158.13 787.57,1162.66C783.044,1167.18 783.045,1174.53 787.573,1179.06Z"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M655.279,1047.38C650.595,1042.69 650.594,1035.09 655.276,1030.4C659.959,1025.72 667.563,1025.72 672.247,1030.4C676.931,1035.09 676.932,1042.69 672.249,1047.37C667.567,1052.06 659.963,1052.06 655.279,1047.38Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path d="M787.57,1162.66C757.196,1132.29 696.052,1071.17 668.855,1043.98"
                             fill="none"
                             stroke='black'
                             strokeWidth="4"
                             style={{pathLength}}
                             transition={transition}
                />

                <motion.path
                    d="M591.255,741.012C591.254,734.389 596.63,729.012 603.253,729.01C609.876,729.009 615.254,734.385 615.255,741.008C615.256,747.631 609.88,753.009 603.257,753.01C596.634,753.012 591.256,747.635 591.255,741.012Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M539.675,740.34C539.736,733.718 545.162,728.39 551.785,728.451C558.408,728.512 563.735,733.938 563.674,740.561C563.614,747.183 558.187,752.511 551.565,752.45C544.942,752.389 539.615,746.963 539.675,740.34Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M351.822,775.882C347.14,771.198 347.142,763.593 351.826,758.911C356.511,754.229 364.115,754.231 368.797,758.916C373.479,763.6 373.477,771.204 368.792,775.886C364.108,780.568 356.504,780.566 351.822,775.882Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M186.144,548.411C181.459,543.731 181.455,536.126 186.135,531.441C190.816,526.755 198.42,526.751 203.106,531.432C207.792,536.113 207.796,543.717 203.115,548.402C198.434,553.088 190.83,553.092 186.144,548.411Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M343.363,600.227C343.386,593.604 348.782,588.246 355.405,588.269C362.028,588.293 367.386,593.689 367.363,600.312C367.339,606.935 361.943,612.293 355.32,612.269C348.697,612.246 343.339,606.85 343.363,600.227Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M167.89,602.867C163.236,598.155 163.283,590.551 167.995,585.897C172.707,581.243 180.311,581.29 184.965,586.002C189.62,590.714 189.572,598.318 184.86,602.972C180.148,607.626 172.544,607.579 167.89,602.867Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M184.83,681.65C180.154,686.341 172.55,686.354 167.859,681.679C163.168,677.003 163.156,669.399 167.831,664.708C172.506,660.017 180.111,660.005 184.802,664.68C189.492,669.355 189.505,676.96 184.83,681.65Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M268.311,486.758C268.309,480.135 273.684,474.757 280.307,474.755C286.93,474.753 292.309,480.128 292.311,486.751C292.313,493.374 286.937,498.753 280.314,498.755C273.691,498.757 268.313,493.381 268.311,486.758Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M480.249,572.5C480.25,565.877 485.628,560.501 492.251,560.502C498.874,560.503 504.25,565.881 504.249,572.504C504.248,579.127 498.87,584.503 492.247,584.502C485.624,584.501 480.248,579.123 480.249,572.5Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M521.61,393.222C526.289,388.535 533.893,388.528 538.581,393.206C543.268,397.885 543.275,405.49 538.596,410.177C533.917,414.864 526.313,414.871 521.626,410.193C516.938,405.514 516.931,397.909 521.61,393.222Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M350.592,417.335C350.595,410.712 355.976,405.338 362.599,405.342C369.222,405.346 374.595,410.726 374.592,417.349C374.588,423.972 369.208,429.346 362.585,429.342C355.962,429.338 350.588,423.958 350.592,417.335Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M831.446,1479.45L831.642,1313.2L545.887,1027.21L545.966,954.765L574.88,925.859L575.036,828.238L603.265,799.799C603.265,799.799 603.26,769.174 603.256,748.21M575.036,828.238L551.085,804.611C551.085,804.611 551.401,770.212 551.609,747.65M546.775,953.962C546.775,953.962 409.63,816.745 365.399,772.491M420.25,827.371L420.503,765.563C420.503,765.563 249.7,594.939 199.719,545.01M355.009,700.138C355.009,700.138 355.224,639.446 355.337,607.469M215.443,633.936L288.722,633.92L215.443,633.936C215.443,633.936 195.514,613.758 181.487,599.557M215.443,633.936C215.443,633.936 195.479,653.967 181.413,668.08M312.208,657.381L312.508,611.739L280.338,579.572C280.338,579.572 280.322,524.209 280.313,493.955M460.575,867.716L460.515,745.247L415.08,699.728C415.08,699.728 415.061,526.018 415.058,466.721M460.515,745.247L492.227,713.556C492.227,713.556 492.242,620.794 492.248,579.702M415.077,644.408L455.781,604.399L455.707,476.232C455.707,476.232 501.514,430.341 525.017,406.795M415.075,598.292L362.518,545.743C362.518,545.743 362.565,462.982 362.588,424.542"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M509.025,758.234C509.015,751.832 503.809,746.642 497.406,746.653C491.004,746.663 485.815,751.87 485.825,758.272C485.836,764.674 491.042,769.863 497.444,769.853C503.847,769.842 509.036,764.636 509.025,758.234Z"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M509.025,758.234C509.015,751.832 503.809,746.642 497.406,746.653C491.004,746.663 485.815,751.87 485.825,758.272C485.836,764.674 491.042,769.863 497.444,769.853C503.847,769.842 509.036,764.636 509.025,758.234Z"
                    fill="none"
                    stroke='rgb(203,29,82)'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                    initial={{x: 80.7666, y: -217.785}}
                />

                <motion.path
                    d="M509.025,758.234C509.015,751.832 503.809,746.642 497.406,746.653C491.004,746.663 485.815,751.87 485.825,758.272C485.836,764.674 491.042,769.863 497.444,769.853C503.847,769.842 509.036,764.636 509.025,758.234Z"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                    initial={{translateX: -82.4061, translateY: -302.697}}
                />

                <motion.path
                    d="M547.979,868.162C552.68,872.828 552.708,880.432 548.042,885.133C543.376,889.833 535.772,889.862 531.072,885.196C526.371,880.53 526.343,872.926 531.009,868.225C535.674,863.525 543.279,863.497 547.979,868.162Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M497.444,769.853C497.488,796.589 497.551,835.015 497.551,835.015C497.551,835.015 519.461,856.763 534.415,871.607"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M472.673,987.688C477.361,992.367 484.965,992.36 489.644,987.672C494.323,982.984 494.315,975.38 489.628,970.702C484.94,966.023 477.336,966.03 472.657,970.718C467.978,975.405 467.986,983.01 472.673,987.688Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M401.173,865.201C401.174,858.578 406.553,853.202 413.175,853.203C419.798,853.204 425.174,858.583 425.173,865.206C425.172,871.828 419.794,877.204 413.171,877.203C406.548,877.202 401.172,871.824 401.173,865.201Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M476.054,974.109C454.076,952.172 413.164,911.339 413.164,911.339C413.164,911.339 413.168,889.336 413.172,872.403"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M273.252,808.347C277.926,813.039 285.531,813.053 290.222,808.378C294.914,803.703 294.928,796.099 290.254,791.407C285.579,786.716 277.975,786.702 273.283,791.376C268.591,796.051 268.577,803.655 273.252,808.347Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M234.436,730.088C234.4,723.465 239.749,718.06 246.371,718.024C252.994,717.989 258.4,723.337 258.435,729.96C258.47,736.583 253.122,741.989 246.499,742.024C239.876,742.059 234.471,736.711 234.436,730.088Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M276.671,794.777C263.871,781.929 246.62,764.615 246.62,764.615C246.62,764.615 246.542,750.063 246.474,737.224"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M149.364,740.918C144.681,745.601 144.681,753.205 149.364,757.888C154.047,762.571 161.651,762.571 166.334,757.888C171.017,753.205 171.017,745.601 166.334,740.918C161.651,736.235 154.047,736.235 149.364,740.918Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M289.209,702.287C295.832,702.281 301.204,696.899 301.198,690.276C301.192,683.653 295.811,678.281 289.188,678.287C282.565,678.293 277.192,683.674 277.198,690.297C277.204,696.92 282.586,702.293 289.209,702.287Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M289.188,678.287C295.811,678.281 301.192,683.653 301.198,690.276C301.204,696.899 295.832,702.281 289.209,702.287C282.586,702.293 277.204,696.92 277.198,690.297C277.192,683.674 282.565,678.293 289.188,678.287Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M207.468,773.512C207.525,780.135 202.195,785.558 195.572,785.616C188.949,785.673 183.526,780.343 183.468,773.72C183.411,767.098 188.741,761.674 195.364,761.617C201.987,761.559 207.41,766.89 207.468,773.512Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M162.94,744.312C182.661,724.591 216.902,690.35 216.902,690.35C216.902,690.35 257.122,690.315 281.998,690.293M281.998,690.293C257.122,690.315 216.902,690.35 216.902,690.35L194.937,712.316C194.937,712.316 195.217,744.699 195.406,766.417"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M542.976,1098.68C547.498,1103.21 554.849,1103.22 559.381,1098.69C563.913,1094.17 563.921,1086.82 559.399,1082.29C554.877,1077.76 547.527,1077.75 542.995,1082.27C538.463,1086.79 538.454,1094.14 542.976,1098.68Z"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M542.976,1098.68C547.498,1103.21 554.849,1103.22 559.381,1098.69C563.913,1094.17 563.921,1086.82 559.399,1082.29C554.877,1077.76 547.527,1077.75 542.995,1082.27C538.463,1086.79 538.454,1094.14 542.976,1098.68Z"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                    initial={{translateX: -334.359, translateY: 155.299}}
                />

                <motion.path
                    d="M164.851,843.719C160.323,839.193 160.323,831.842 164.849,827.314C169.376,822.787 176.727,822.786 181.254,827.313C185.782,831.84 185.782,839.19 181.256,843.718C176.729,848.245 169.378,848.246 164.851,843.719Z"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M164.851,843.719C160.323,839.193 160.323,831.842 164.849,827.314C169.376,822.787 176.727,822.786 181.254,827.313C185.782,831.84 185.782,839.19 181.256,843.718C176.729,848.245 169.378,848.246 164.851,843.719Z"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                    initial={{translateX: 124.588, translateY: -99.1224}}
                />

                <motion.path
                    d="M542.995,1082.27C525.861,1065.1 502.533,1041.72 502.533,1041.72L379.396,1041.82C379.396,1041.82 232.965,895.418 181.256,843.718"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M157.944,922.413C153.277,917.713 153.303,910.109 158.003,905.442C162.702,900.776 170.306,900.802 174.973,905.501C179.64,910.201 179.613,917.805 174.914,922.472C170.215,927.139 162.61,927.112 157.944,922.413Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M201.278,1033.74C196.596,1038.42 188.992,1038.42 184.307,1033.74C179.623,1029.06 179.621,1021.46 184.303,1016.77C188.985,1012.09 196.589,1012.09 201.273,1016.77C205.958,1021.45 205.96,1029.05 201.278,1033.74Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M300.841,963.374L215.544,963.386C215.544,963.386 188.542,936.195 171.532,919.066M254.632,963.381C254.632,963.381 218.318,999.713 197.88,1020.16"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M316.346,933.914C311.655,929.239 311.64,921.635 316.315,916.943C320.989,912.251 328.594,912.237 333.285,916.912C337.977,921.586 337.991,929.19 333.317,933.882C328.642,938.574 321.038,938.588 316.346,933.914Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M237.303,838.757C232.64,834.053 232.673,826.449 237.377,821.786C242.08,817.124 249.685,817.157 254.347,821.86C259.01,826.564 258.977,834.168 254.273,838.831C249.57,843.493 241.965,843.46 237.303,838.757Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M824.819,1446.62L824.846,1318.63L514.235,1008.17L464.813,1008.3L334.088,877.375L334.323,810.616L297.65,774.025C297.65,774.025 297.666,759.575 297.68,746.798M419.794,963.212L362.838,963.294C362.838,963.294 343.647,944.174 329.917,930.494M334.088,877.375L292.592,877.486C292.592,877.486 267.241,851.912 250.894,835.422"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M294.146,1078.33C287.523,1078.33 282.147,1072.95 282.148,1066.33C282.149,1059.7 287.527,1054.33 294.15,1054.33C300.773,1054.33 306.149,1059.71 306.148,1066.33C306.147,1072.95 300.769,1078.33 294.146,1078.33Z"
                    stroke='rgb(146,28,106)'
                    fill='rgb(146,28,106)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M236.524,1105.33C229.901,1105.33 224.523,1099.95 224.523,1093.33C224.523,1086.71 229.899,1081.33 236.522,1081.33C243.145,1081.33 248.523,1086.71 248.523,1093.33C248.523,1099.95 243.147,1105.33 236.524,1105.33Z"
                    stroke='rgb(146,28,106)'
                    fill='rgb(146,28,106)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M264.183,1148.12C259.523,1152.83 251.919,1152.87 247.212,1148.21C242.505,1143.55 242.467,1135.94 247.126,1131.24C251.786,1126.53 259.39,1126.49 264.097,1131.15C268.803,1135.81 268.842,1143.42 264.183,1148.12Z"
                    stroke='rgb(146,28,106)'
                    fill='rgb(146,28,106)'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M818.091,1422.91L818.068,1324.12L630.725,1136.87L544.005,1136.83L473.534,1066.36C473.534,1066.36 349.862,1066.34 301.348,1066.33M473.534,1066.36L446.596,1093.32C446.596,1093.32 297.652,1093.33 243.723,1093.33M518.435,1111.26L283.736,1111.31C283.736,1111.31 271.122,1124.06 260.72,1134.56"
                    fill="none"
                    stroke='rgb(146,28,106)'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M316.27,1167.38C311.58,1162.71 311.569,1155.1 316.246,1150.41C320.922,1145.72 328.526,1145.71 333.216,1150.39C337.906,1155.06 337.917,1162.67 333.241,1167.36C328.564,1172.05 320.96,1172.06 316.27,1167.38Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M428.942,1185.04C422.319,1185.03 416.945,1179.65 416.949,1173.03C416.954,1166.4 422.334,1161.03 428.957,1161.04C435.58,1161.04 440.954,1166.42 440.949,1173.04C440.945,1179.67 435.565,1185.04 428.942,1185.04Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M386.888,1268.24C380.265,1268.24 374.889,1262.86 374.891,1256.24C374.892,1249.62 380.271,1244.24 386.894,1244.24C393.517,1244.24 398.892,1249.62 398.891,1256.25C398.889,1262.87 393.511,1268.24 386.888,1268.24Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M811.368,1430.13L811.403,1329.89L696.044,1214.43L380.495,1214.48C380.495,1214.48 348.698,1182.77 329.842,1163.97M614.997,1214.44L573.666,1173.13C573.666,1173.13 477.989,1173.07 436.149,1173.04M551.411,1214.45L509.613,1256.27C509.613,1256.27 431.302,1256.25 394.091,1256.24"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />

                <motion.path
                    d="M588.853,1256.61C584.191,1251.9 584.226,1244.3 588.931,1239.63C593.636,1234.97 601.24,1235.01 605.901,1239.71C610.563,1244.42 610.528,1252.02 605.823,1256.68C601.119,1261.35 593.515,1261.31 588.853,1256.61Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M535.23,1362.57C528.607,1362.57 523.234,1357.18 523.24,1350.56C523.245,1343.94 528.627,1338.57 535.25,1338.57C541.873,1338.58 547.245,1343.96 547.24,1350.58C547.234,1357.2 541.853,1362.58 535.23,1362.57Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M294.244,1360C289.578,1364.7 281.974,1364.73 277.274,1360.06C272.574,1355.4 272.546,1347.79 277.212,1343.09C281.878,1338.39 289.483,1338.36 294.183,1343.03C298.883,1347.7 298.91,1355.3 294.244,1360Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M371.324,1372.11C366.634,1376.79 359.03,1376.78 354.353,1372.09C349.676,1367.4 349.686,1359.79 354.375,1355.12C359.064,1350.44 366.669,1350.45 371.346,1355.14C376.023,1359.83 376.013,1367.43 371.324,1372.11Z"
                    stroke='black'
                    fill='black'
                    strokeWidth="1"
                    style={{
                        pathLength,
                        opacity: fillOpacity,
                    }}
                    transition={transition}
                />

                <motion.path
                    d="M804.695,1413.98L804.697,1335.43L757.689,1288.46L481.833,1288.38L467.854,1274.04L243.903,1274.19C243.903,1274.19 233.806,1264.4 223.731,1254.36M637.275,1288.42C637.275,1288.42 616.747,1267.71 602.445,1253.27M675.187,1288.43L613.047,1350.64C613.047,1350.64 568.82,1350.6 542.44,1350.58M481.833,1288.38L445.524,1325.08L312.048,1325.03C312.048,1325.03 300.569,1336.6 290.801,1346.44M401.494,1325.07C401.494,1325.07 381.872,1344.64 367.947,1358.53"
                    fill="none"
                    stroke='black'
                    strokeWidth="4"
                    style={{pathLength}}
                    transition={transition}
                />
            </svg>
        </div>
    );
}

export default PathDrawingAnimation;
