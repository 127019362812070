import React from "react";
import "./App.css"; // Use this for additional custom styles
import "./components/Buttons.css"
import HomePage from "pages/Home/HomePage";

function App() {
    return (
        <HomePage/>
    );
}

export default App;
