/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import {
  Button,
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react'
import styles from '../../components/Components.module.css'
import { Link } from 'react-router-dom';
import Footer from '../Common/Footer'
import Project from './components/Project'
import ResponsiveContainer from "../Common/ResponsiveContainer";


const HomePage = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em', backgroundColor: '#fff' }} basic vertical>
      <Grid container centered stackable>
        <Grid.Row >
          <Grid.Column floated='left' width={5} style={{minHeight: '400px'}}>
            <Project title={'Dennet Reactor'} link_to={'/portfolio'}>
              Software die nieuwe acties initiëert op basis van een evenement.
              Een actie is bijvoorbeeld een nieuw MQTT bericht of een
              schrijfactie naar de database.<br/><br/>
            </Project>
          </Grid.Column>
            <Grid.Column width={5} style={{minHeight: '400px'}}>
              <Project title={'Dennet Connect'} link_to={'/portfolio'}>
                Software waarmee berichten van endpoint naar endpoint kan worden gestreamd.
                Connectors met onder andere HTTP en MQTT. Biedt
                mogelijkheid berichten te transformeren (denk aan filter, enrich, normalize).
              </Project>
          </Grid.Column>
            <Grid.Column floated='right' width={5} style={{minHeight: '400px'}}>
              <Project title={'BeterMeter'} link_to={'/portfolio'}>
                Een LoRaWAN end device met een oplaadbare batterij die CO2, VOC, temperatuur en
                luchtvochtigheid meet en verzendt naar de dichtsbijzijnde gateway van The Things Network.
              </Project>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment className={styles.greyTopToBottom} style={{ padding: '8em 0em' }} basic vertical>
      <Grid container stackable style={{ backgroundColor: 'white', borderRadius: '25px' }}>
        <Grid.Row centered>
          <Grid.Column width={6}>
            <Header as='h3' style={{ fontSize: '3.6em'}}>
              Naadloze
              <span className={styles.lineargradient}> integratie</span>
            </Header>
            <p className={styles.standardP}>
              Dennet ontwerpt bedrijfssoftware en technische producten die naadloos integreren en
              met een focus op de gebruikersbehoeften zijn ontworpen.
              </p>
              <p className={styles.standardP}>
              Innovatie
              waarbij elke interactie natuurlijk aanvoelt en bescherming van persoonlijke gegevens als prioriteit wordt beschouwd.
            </p>
          </Grid.Column>
          <Grid.Column width={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}  >
            <Image rounded size='large' src='img/web_team_rocket.svg' style={{display: 'inline-block'}}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>


    <Segment style={{ padding: '8em 0em' }} vertical basic>
      <Grid container celled='' columns='equal' stackable>
        <Grid.Row textAlign='center'>
          <Grid.Column width={8} floated='left' style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Internet of Things
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Slim besturen
            </p>
            <Image src='img/isometric_design_concept_illustration_house_with_solar_energy_storage_panels.svg' style={{ display: 'inline-block', maxHeight: '15em', paddingBottom: '2em'}}/>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                <Button inverted color='purple2' as={Link} to='/iot' size='huge'>Check it Out</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>

          <Grid.Column className={styles.vLine} width={8} floated='right' style={{ paddingBottom: '5em', paddingTop: '5em' }}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Interim
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              Wat kan Dennet doen voor uw bedrijf?
            </p>
            <Image src='img/data_server_hosting_network.svg' style={{ display: 'inline-block', maxHeight: '15em', paddingBottom:'2em'}}/>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                <Button inverted color='purple2' as={Link} to='/interim' size='huge'>Check it Out</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>


    <Footer />
  </ResponsiveContainer>
)

export default HomePage
