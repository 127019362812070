import {Divider, Header, Icon, Segment} from "semantic-ui-react";
import React from "react";
import {Link} from "react-router-dom";
import './Components.project.css'


const Project = ({title, link_to, children})=> {
    return (
        <Segment style={{ height: '100%', borderRadius: '25px', boxShadow: '0px 5px 50px #00000029' }}>
            <Segment basic style={{}}>
                <Header as='h2' style={{wordBreak: 'break-word'}}>{title}</Header>
                <Divider
                    style = {{height: '3px', borderRadius: '3px', background: 'linear-gradient(40deg, var(--left-2), var(--right-2)'}}
                />
            </Segment>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{maxWidth: '90%', textAlign: 'justify', textJustify: 'inter-word'}}>
            {children}
            </p>
            <Link className='custom-link' to={link_to} style={{ paddingLeft: '5%', alignSelf: 'flex-start', position: 'absolute', bottom: '10%' }}>Lees verder <Icon name='arrow right'/> </Link>
            </div>
        </Segment>
    );
}

export default Project;