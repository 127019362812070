import React from 'react'
import PropTypes from 'prop-types'
import 'semantic-ui-css/semantic.min.css';
import {
    Image,
    Grid,
    Header,
} from 'semantic-ui-react'

const HomepageHeading = ({mobile}) => {
    return (
        <header>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row style={{minHeight: '75vh'}}>
                    <Grid.Column width={10}>
                        <Image
                            src='img/Den-gradient.svg'
                            style={{display: 'inline-block', paddingBottom: '2em'}}
                        />
                    </Grid.Column>
                    {(!mobile &&
                    <Grid.Column width={6}>
                            <Image
                            src='img/dennet-stroke.svg'
                            alt={"Dennet logo by b9"}
                            style={{
                                marginBottom: '2em',
                                marginTop: mobile ? '1.5em' : '1em',
                                maxWidth: '70vw',
                                display: 'inline-block'
                            }}
                        />
                            <Header
                            as='h2'
                            content=''
                            style={{
                                fontSize: mobile ? '1.5em' : '1.7em',
                                fontWeight: 'normal',
                                marginTop: mobile ? '0.5em' : '1.0em',
                            }}
                        />
                    </Grid.Column>)}
                </Grid.Row>
            </Grid>
        </header>
    );
};

HomepageHeading.propTypes = {
    mobile: PropTypes.bool.isRequired,
}


export default HomepageHeading
