import 'semantic-ui-css/semantic.min.css';
import {
  Container,
  Grid,
  Header, Icon,
  Image,
  List,
  Segment,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';

const Footer = () => {
return (
<Segment inverted vertical style={{ backgroundColor: 'var(--purple-1)', padding: '5em 0em' }}>
  <Container>
    <Grid divided inverted stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <Header inverted as='h4' content='Informatie' />
          <List link inverted>
            <List.Item as={Link} to='/portfolio' name=''>Portfolio</List.Item>
            <List.Item as={Link} to='/' name=''>Over</List.Item>
            <List.Item as={Link} to='/' name=''>Contact</List.Item>
            <List.Item as={Link} to='/' name=''>Technologie</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={3}>
          <Header inverted as='h4' content='Services' />
          <List link inverted>
            <List.Item as={Link} to='/' name=''>Automation Pre-Order</List.Item>
            <List.Item as={Link} to='/interim' name=''>Interim</List.Item>
            <List.Item as={Link} to='/iot' name=''>IoT</List.Item>
            <List.Item as={Link} to='/data' name=''>Data</List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as='h4' inverted>
            Contact
          </Header>
          <p> email:    <a href='mailto:info@dennet.io' style={{color: 'white', textDecoration:'underline'}}>info@dennet.io</a> </p>
          <p> telefoon: +316 43 53 59 26</p>
          <p> kvk:      86742671</p>
        </Grid.Column>
        <Grid.Column width={2}>
          <Image src='img/hello.svg' style={{ display: 'inline-block'}}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column textAlign='center' width={6} style={{color: '#ffffff50', paddingTop: '2em'}}> Made with <Icon name='heart' /> by Dennet in Rotterdam.</Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
</Segment>
);
}

export default Footer
