import React from 'react';
import {useLocation} from 'react-router-dom';
import DesktopContainer from './DesktopContainer'; // Adjust the import path as necessary

function DesktopContainerWrapper(props) {
    const location = useLocation();

    return <DesktopContainer {...props} location={location}/>;
}

export default DesktopContainerWrapper;
