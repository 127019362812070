import React, {useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import PropTypes from "prop-types";

function SkillData({skillsData}) {

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeListener = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    return (
        <Grid.Row>
            {skillsData.map((data, index) => {
                let float = null;
                let textAlignment;
                if (index === 0) {
                    textAlignment = screenWidth > 767 ? 'left' : 'center';
                } else if (index === skillsData.length - 1) {
                    textAlignment = screenWidth > 767 ? 'right' : 'center';
                    float = 'right'
                } else {
                    textAlignment = 'center';
                }
                return (
                    <Grid.Column
                        width={data.columnWidth}
                        floated={float}
                        textAlign={textAlignment}
                    >
                        <span style={{fontSize: '2em'}}>{data.skill}</span>
                    </Grid.Column>
                );
            })}
        </Grid.Row>
    );
}

SkillData.propTypes = {
    skillsData: PropTypes.arrayOf(
        PropTypes.shape({
            columnWidth: PropTypes.number.isRequired,
            skill: PropTypes.string.isRequired,
        }),
    ).isRequired,
}

export default SkillData
