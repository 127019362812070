import {Divider} from "semantic-ui-react";
import React from "react";
import AccordionProcessSubStep from "./AccordionProcessSubStep";

function ProcessSteps({procSteps}) {

    return (
        <>
            <Divider className='accordionDivider'/>
            {
                procSteps.map((data) => {
                    return (
                        <>
                            <AccordionProcessSubStep info={{title: data.step, subtitle: data.subtitle}} style={{}}/>
                            <Divider className='accordionDivider'/>
                        </>
                    );
                })
            }
        </>
    );
}

export default ProcessSteps