/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import Footer from '../Common/Footer'
import ResponsiveContainer from "../Common/ResponsiveContainer";
import UnderConstruction from "../../components/UnderConstruction";

const InternetOfThings = () => (
    <ResponsiveContainer>
        <UnderConstruction/>
        <Footer/>
    </ResponsiveContainer>
)

export default InternetOfThings
