import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import "./index.css";
import './assets/fonts/JetBrains/stylesheet.css'
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import Interim from "pages/Interim/Interim";
import InternetOfThings from "pages/IoT/InternetOfThings";
import Data from 'pages/Data/Data';
import ScrollToTop from './components/ScrollToTop'
import Portfolio from "./pages/Portfolio/Portfolio";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="/portfolio" element={<Portfolio/>}/>
                <Route path="/interim" element={<Interim/>}/>
                <Route path="/iot" element={<InternetOfThings/>}/>
                <Route path="/data" element={<Data/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
