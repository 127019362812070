import {Button, Grid, Header, Icon, Image, Segment} from "semantic-ui-react";
import styles from "../../../components/Components.module.css";
import React from "react";
import {Link} from "react-router-dom";

const Projects = ({screenWidth}) => {
    const isMobile = screenWidth <= 768;  // Define your mobile breakpoint here
    return(
        <>
            <Segment className={styles.segmentWithBorder} style={{ padding: '0em 0em',background: `linear-gradient(180deg, var(--light-grey), #fff)`,  borderBottom: '0px' }} vertical attached basic>
                <Grid container stackable verticalAlign='middle' >
                    <Grid.Row centered style={{ minHeight: '80vh', margin: '3em 0em'}}>
                        <Grid.Column width={6} textAlign='center' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                            <Segment basic style={{padding: '3em 0em', marginBottom: '3em', borderRadius: '30px', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', maxWidth: '400px'}}>
                                <Header as='h3' style={{ fontSize: '3.6em', textAlign: 'left', alignSelf: 'flex-start'}}>
                                    Dennet
                                    <span className={styles.lineargradient}> Reactor</span>
                                </Header>
                                <p style={{ fontSize: '1.2em', textAlign: 'justify', textJustify: 'inter-word', marginBottom: '3em'}}>
                                    Een programma dat nieuwe acties initiëert op basis van een evenement.
                                    Een actie is bijvoorbeeld een nieuw MQTT bericht of een
                                    schrijfactie naar de database (InfluxDB of PostgreSQL).
                                    Voor visualisatie wordt Grafana gebruikt.
                                    <br/><br/>
                                    Het systeem wordt gebruikt als smarthomesysteem in combinatie met Zigbee2MQTT.
                                    Maar het kan bijvoorbeeld ook meetgegevens van een groot aantal LoRaWAN-sensoren
                                    verwerken.
                                    <br/><br/> Gebouwd in Rust en beschikbaar als Docker image.
                                </p>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={8} floated='right' textAlign='center'>
                            <Image rounded src='img/Automaton.svg' size='huge' style={{display: 'inline-block', borderRadius: '25px', boxShadow: '0px 5px 50px #00000029', backgroundColor: '#fafafa'}}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{ padding: '0em 0em', background: `linear-gradient(0deg, var(--light-grey), #fff)` }} vertical attached basic>
                <Grid container stackable verticalAlign='middle' >
                    <Grid.Row centered style={{ minHeight: '80vh', margin: '3em 0em'}}>
                        {!isMobile && (
                        <Grid.Column width={9} floated='left' style={{height: '100%', borderRadius: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Image rounded src='img/Connect.svg' size='huge' style={{display: 'inline-block'}}/>
                        </Grid.Column>)}
                        <Grid.Column width={6} textAlign='center' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                            <Segment basic style={{
                                padding: '3em 0em',
                                marginBottom: '3em',
                                borderRadius: '30px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: '400px'
                            }}>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start'
                                }}>
                                    <Header as='h3' style={{fontSize: '3.6em', textAlign: 'left' }}>
                                        Dennet
                                        <span className={styles.lineargradient}> Connect</span>
                                    </Header>
                                    <p style={{
                                        fontSize: '1.2em',
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        marginBottom: '3em',
                                        maxWidth: '400px'
                                    }}>
                                        Software waarmee berichten van endpoint naar endpoint kunnen worden gestreamd.
                                        Connectors met HTTP en MQTT. Biedt
                                        mogelijkheid berichten te transformeren (denk aan filter, enrich, normalize).
                                        <br/> <br/>
                                        Het wordt gebruikt in combinatie met Reactor om bijvoorbeeld REST API's te
                                        benaderen of meetgegevens van een LoRaWAN-sensor van The Things Network op een
                                        queue
                                        bij een andere MQTT broker te plaatsen.
                                        Of om verschillende instanties van een Dennet Reactor systeem met elkaar te
                                        verbinden.
                                        <br/><br/> Gebouwd in Rust en beschikbaar als Docker image.
                                    </p>
                                </div>
                            </Segment>
                        </Grid.Column>
                        {isMobile && (
                            <Grid.Column width={8} textAlign='center'>
                                <Image rounded src='img/Connect.svg' size='huge' style={{display: 'inline-block'}}/>
                            </Grid.Column>)}
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{ padding: '0em 0em', backgroundColor: 'var(--light-grey)' }} vertical attached basic>
                <Grid container stackable verticalAlign='middle' >
                    <Grid.Row centered style={{ minHeight: '80vh', margin: '3em 0em', backgroundColor: 'white', borderRadius: '25px' }}>
                        <Grid.Column width={7} textAlign='center' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                            <Segment basic style={{padding: '3em 0em', marginBottom: '3em', borderRadius: '30px', maxWidth: '400px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
                                    <Header as='h3' style={{fontSize: '3.6em', textAlign: 'left' }}>
                                        BeterMeter
                                        <span className={styles.lineargradient}> </span>
                                    </Header>
                                    <p style={{
                                        fontSize: '1.2em',
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        marginBottom: '3em',
                                        maxWidth: '400px'
                                    }}>
                                        Een LoRaWAN end device met een oplaadbare batterij die CO2, VOC, temperatuur en
                                        luchtvochtigheid meet en verzendt naar de dichtsbijzijnde gateway van The Things Network.
                                        <br/><br/>
                                        Prototypes zijn gebouwd met Arduino, maar een update naar FreeRTOS op een STM32WL55
                                        is bijna afgerond.
                                    </p>
                                </div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='center'>
                            <Image rounded size='large' src='img/DSC_0267.png' style={{display: 'inline-block'}}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment className={styles.segmentWithBorder} style={{ padding: '0em 0em',background: `linear-gradient(180deg, var(--light-grey), #fff)`, borderBottom: '0px' }} vertical attached basic>
                <Grid container stackable verticalAlign='middle' >
                    <Grid.Row centered style={{ minHeight: '80vh', margin: '3em 0em'}}>
                        {!isMobile && (
                            <Grid.Column width={8} floated='left' style={{height: '100%', borderRadius: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Image rounded src='img/DSC_0258.png' size='large' style={{display: 'inline-block'}}/>
                            </Grid.Column>)}
                        <Grid.Column width={7} textAlign='center' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                            <Segment basic style={{padding: '3em 0em', marginBottom: '3em', borderRadius: '30px', maxWidth: '400px'}}>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
                                <Header as='h3' style={{ fontSize: '3.6em', textAlign: 'left' }}>
                                    Zigbee simple switch
                                    <span className={styles.lineargradient}> </span>
                                </Header>
                                <p style={{ fontSize: '1.2em', textAlign: 'justify', textJustify: 'inter-word', marginBottom: '3em', maxWidth: '400px'}}>
                                    Een simpele schakelaar waarmee je via een Zigbee netwerk (Zigbee2MQTT) iets aan en uit kan schakelen.
                                    <br/><br/>
                                    Met deze schakelaar is het bijvoorbeeld mogelijk om een LED lamp die wordt gevoed
                                    door batterijen van stroom te voorzien en aan en uit schakelen met het bestaande
                                    smarthomesysteem.
                                    <br/><br/>
                                    Gebouwd met de ZStack en TI-RTOS van Texas Instruments.
                                </p>
                                </div>
                            </Segment>
                        </Grid.Column>
                        {isMobile && (
                        <Grid.Column width={8} textAlign='center'>
                            <Image rounded src='img/DSC_0258.png' size='large' style={{display: 'inline-block'}}/>
                        </Grid.Column>)}
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{ padding: '0em 0em', background: `linear-gradient(0deg, var(--light-grey), #fff)` }} vertical attached basic>
                <Grid container stackable verticalAlign='middle' >
                    <Grid.Row centered style={{ minHeight: '80vh', margin: '3em 0em', padding: '3em 2em'}}>
                        <Grid.Column width={7} textAlign='center' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                            <Segment basic style={{padding: '3em 0em', marginBottom: '3em', borderRadius: '30px', maxWidth: '400px'}}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start'
                                }}>
                                    <Header as='h3' style={{fontSize: '3.6em', textAlign: 'left', wordBreak: isMobile ? 'break-word' : null}}>
                                        Luchtkwaliteitsindex
                                        <span className={styles.lineargradient}> </span>
                                    </Header>
                                    <p style={{
                                        fontSize: '1.2em',
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        marginBottom: '3em',
                                        maxWidth: '400px'
                                    }}>
                                        Een integratie met het Samenmeten platform van het RIVM waarmee op basis van
                                        coördinaten en een straal de huidige buitenluchtkwaliteit te kunnen bepalen met alle
                                        metingen die in het betreffende gebied worden gevonden.
                                        <br/><br/>
                                        Datacollectie en -opslag wordt periodiek uitgevoerd door software geschreven in Rust.
                                        De luchtkwaliteitsindex wordt berekend met een PL/pgSQL functie.
                                    </p>
                                </div>
                            </Segment>
                        </Grid.Column>
                            <Grid.Column width={8} textAlign='center'>
                                <Image rounded src='img/Rotterdam.svg' size='large'
                                       style={{display: 'inline-block'}}/>
                            </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Segment style={{padding: '0em 0em', backgroundColor: 'var(--light-grey)'}} vertical attached basic>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row centered style={{
                        minHeight: '80vh',
                        margin: '3em 0em',
                        backgroundColor: 'white', borderRadius: '25px'}}>
                        {!isMobile && (
                            <Grid.Column width={8} floated='left' style={{height: '100%', borderRadius: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Image rounded src='img/DSC_0262.png' size='large' style={{display: 'inline-block'}}/>
                            </Grid.Column>)}
                        <Grid.Column width={7} textAlign='center' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                            <Segment basic style={{padding: '3em 0em', marginBottom: '3em', borderRadius: '30px', maxWidth: '400px'}}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start'
                                }}>
                                    <Header as='h3' style={{
                                        fontSize: '3.6em',
                                        textAlign: 'left',
                                    }}>
                                        Expeditie Next -> <br/> AI Trainer
                                    </Header>
                                    <p style={{
                                        fontSize: '1.2em',
                                        textAlign: 'justify',
                                        textJustify: 'inter-word',
                                        marginBottom: '3em',
                                        maxWidth: '400px'
                                    }}>
                                        Hoe leg je kinderen uit hoe een AI model wordt getraind?<br/>
                                        Links op het scherm staat een doelkleur.
                                        Elke draaiknop op de trainer beïnvloed een van de vier RGBA waardes van de kleur
                                        rechts. De deelnemer moet zelf uitvinden welke.
                                        Wanneer de doelkleur is nagemaakt kan men op de rode knop drukken om de ronde te
                                        beëindigen en de score op te slaan.
                                        <br/><br/>
                                        Ontwikkeld voor de Wetenschapsbeurs Expeditie Next voor kinderen met een
                                        leeftijd van 6 tot 12 jaar.

                                    </p>
                                    <div className="ui buttons" style={{alignSelf: 'flex-start'}}>
                                        <Button color='custom' as={Link} to={"https://github.com/dennet-io/ai-trainer"}>
                                            <a href="https://github.com/dennet-io/ai-trainer" target="_blank"
                                               rel="noopener noreferrer"
                                               style={{marginLeft: '0.5em'}}>
                                                <Icon size='big' inverted name='github'/>
                                            </a>
                                        </Button>
                                    </div>
                                </div>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign='center'>
                            <Image rounded src='img/DSC_0262.png' size='large' style={{display: 'inline-block'}}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

        </>
    );
}

export default Projects