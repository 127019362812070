/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React, {useState, useEffect} from 'react'
import 'semantic-ui-css/semantic.min.css';
import Footer from '../Common/Footer'
import ResponsiveContainer from '../Common/ResponsiveContainer'
import Projects from './components/Projects'

const Portfolio = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeListener = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);


    return (
        <ResponsiveContainer>
            <Projects screenWidth={screenWidth}/>
            <Footer/>
        </ResponsiveContainer>
    );
}

export default Portfolio
