/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React, {useState, useEffect} from 'react'
import 'semantic-ui-css/semantic.min.css';
import {
    Divider,
    Grid,
    Header,
    Segment,
} from 'semantic-ui-react'
import styles from '../../components/Components.module.css'
import Footer from '../Common/Footer'
import ResponsiveContainer from '../Common/ResponsiveContainer'
import ProcessSteps from "./components/ProcessSteps";
import SkillData from "./components/SkillData";
import './interim.css'
import processSteps from './components/processSteps.json';
import skillData from './components/skillData.json'

const Interim = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeListener = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);


    return (
        <ResponsiveContainer>
            <Segment basic aligned center vertical>
                <Grid container stackable verticalAlign='middle'>

                    <Grid.Row className='headerRow' style={{paddingTop: screenWidth > 767 ? '10rem' : '2rem' }}>
                        <Grid.Column>
                            <Header>
                                Hoe ga ik te werk?
                                <span className={styles.lineargradient}> </span>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className='contentColumn'>
                        <Grid.Column width={16}>
                            <p className='interimParagraph'>
                                Een succesvolle integratie of automatisering begint en eindigt bij de gebruikers. 
                                Daarom betrek ik medewerkers, de experts, van begin tot eind bij het ontwerpproces.
                                Tijdens de analyses met de experts, zet ik mijn technische kennis in om
                                oplossingen te vinden die goed aansluiten bij de aanwezige behoeftes. 
                                Deze werkwijze resulteert in een breedgedragen en efficiënt werkend product.
                            </p>
                            <p className='interimParagraph'>
                            </p>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className='headerRow'>
                        <Grid.Column>
                            <Header>
                                Stappen
                            </Header>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column className='contentColumn' style={{ alignItems: screenWidth > 767 ? 'center' : null}}>
                            <ProcessSteps procSteps={processSteps}/>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row className='headerRow'>
                        <Grid.Column>
                            <Header>
                                Over mij
                            </Header>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={16} className='contentColumn'>
                            <p className='interimParagraph'>
                                Ik ben gefascineerd door de technologie van de jaren '90, vroege computertechnologie en
                                wiskunde.
                                Het heeft lang geduurd voordat ik dit helder had en deze interesses een plaats in mijn
                                leven kon geven.
                                Mijn carrière heeft daardoor ook een ongebruikelijke weg afgelegd. Ik begon op de Sales 
                                afdeling van BMW voor de Nederlandse markt en groeide daar uit tot het aanspreekpunt voor IT. 
                                Daarom ben ik nu in staat een brug te vormen tussen de business en IT. 
                            </p>
                            <p className='interimParagraph'>
                                Mijn technische vaardigheden, liefde voor ontdekking en zakelijk inzicht
                                zet ik graag in om de productiviteit en efficiëntie van uw organisatie te verbeteren.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>

            <Divider horizontal style={{padding: '5em'}}>
                <h3 style={{fontSize: '2em'}}>Skills</h3>
            </Divider>


            <Segment vertical basic style={{padding: '0% 10% 8em'}}>
                <Grid container stackable verticalAlign='middle' textAlign='center'>
                    { Object.keys(skillData).map(key => (
                            <SkillData key={key} skillsData={skillData[key]}/>
                        )
                    )}
                </Grid>
            </Segment>

            <Footer/>
        </ResponsiveContainer>
    );
}

export default Interim
