/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import Footer from '../Common/Footer'
import MapChart from './components/MapChart';
import ResponsiveContainer from "../Common/ResponsiveContainer";

const Data = () => (
    <ResponsiveContainer>
        <MapChart/>
        <Footer/>
    </ResponsiveContainer>
)

export default Data
