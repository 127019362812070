import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createMedia} from '@artsy/fresnel'
import {Segment, Menu, Container, Image } from 'semantic-ui-react';
import {Link, NavLink} from 'react-router-dom';
import ContactModal from '../../../components/Contact';
import HomepageHeading from '../../Home/components/HomepageHeading';
import {InView} from 'react-intersection-observer'; // Ensure you have this for the InView component
import styles from '../DennetMenu.module.css'

const {Media} = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

class DesktopContainer extends Component {
    state = {}

    toggleFixedMenu = (inView) => this.setState({fixed: !inView})

    render() {
        const {children, location} = this.props;
        const {fixed} = this.state;

        // Determine if the current menu item is active based on the location
        const isActive = (path) => location.pathname === path;

        return (
            <Media greaterThan='mobile'>
                <InView onChange={this.toggleFixedMenu}>
                    <Segment textAlign='center' style={{background: `linear-gradient(180deg, var(--light-grey), #fff)`, padding: '0em'}} vertical>
                        <Menu
                            fixed={location.pathname === '/' || location.pathname === '/portfolio' ? (fixed ? null : null) : 'top'}
                            borderless
                            size='medium'
                        >
                            <Container>
                                <Menu.Item as={Link} to='/' active={isActive('/')}>
                                    <Image src='img/dennet-black.svg' alt='Dennet logo by b9' className={styles.dennetLogo}/>
                                </Menu.Item>
                                <Menu.Item as={NavLink} to='/portfolio' active={isActive('/portfolio')}>
                                    Portfolio
                                </Menu.Item>
                                <Menu.Item as={NavLink} to='/interim' active={isActive('/interim')}>
                                    Interim
                                </Menu.Item>
                                <Menu.Item as={NavLink} to='/iot' active={isActive('/iot')}>
                                    IoT
                                </Menu.Item>
                                <Menu.Item as={NavLink} to='/data' active={isActive('/data')}>
                                    Data
                                </Menu.Item>
                                <Menu.Item position='right'>
                                    <ContactModal/>
                                </Menu.Item>
                            </Container>
                        </Menu>
                        {isActive('/') ? <HomepageHeading mobile={false}/> : null}
                    </Segment>
                </InView>
                {children}
            </Media>
        );
    }
}

DesktopContainer.propTypes = {
    children: PropTypes.node,
    location: PropTypes.object.isRequired, // Ensure location is marked as required
}

// Note: Now, you should use DesktopContainerWithRouter instead of DesktopContainer directly in your app
export default DesktopContainer
